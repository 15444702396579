<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-12 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class=""> สาขา:</label>
                      <multiselect
                        v-model="filter.branchId"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class=""> ชื่อ: </label>
                      <b-form-input
                        v-model="filter.customer"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="text-end"> เบอร์โทรศัพท์: </label>
                      <b-form-input
                        v-model="filter.contactInfo"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <!-- <div class="col-7 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class=""> เรื่องติดต่อ:</label>
                      <b-form-input
                        v-model="filter.subject"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div> -->
                  <div class="col-5 col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> ช่องทางติดต่อ:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.contactType"
                        :options="teamOption"
                        :show-labels="false"
                        label="type"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-6 col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> วันที่เริ่มต้น:</label>
                      <date-picker
                        v-model="filter.startDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                      >
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> วันที่สิ้นสุด:</label>
                      <date-picker
                        v-model="filter.endDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                      >
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-7 col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> ประเภทลูกค้า:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.type"
                        :options="customerTypeOption"
                        :show-labels="false"
                        label="nameTh"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <!-- <router-link
                      :to="{ name: 'add-contactJob' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i
                      >เพิ่มการติดต่อ </router-link
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link
                      :to="{ name: 'contact-calendar' }"
                      class="btn btn-primary"
                    >
                      <i class="uil-calendar-alt"></i>
                    </router-link> -->
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData :fields="fields" :items="rowData">
                <!-- <template #cell(sellPrice)="rowData">
                  {{ Number(rowData.item.sellPrice).toLocaleString() }}
                </template> -->
                <template #cell(index)="rowData" v-if="this.currentPage > 1">
                  {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowData" v-else>
                  {{ rowData.index + 1 }}
                </template>
                <template #cell(callDirection)="rowData">
                  <span v-if="rowData.item.callDirection === 'IN'">
                    สายโทรเข้า
                  </span>
                  <span v-if="rowData.item.callDirection === 'OUT'">
                    สายโทรออก
                  </span>
                  <span v-if="rowData.item.callDirection == null">
                    -
                  </span>
                </template>
                <template #cell(duration)="rowData">
                  {{ getSeconds(rowData.item.duration) }}
                </template>

                <template #cell(action)="rowData">
                  <ul
                    class="list-inline mb-0"
                    v-if="rowData.item.ctJobIdBase64"
                  >
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="View"
                        :to="{
                          name: 'edit-contactJob',
                          params: { ctJobId: rowData.item.ctJobIdBase64 },
                        }"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </router-link>
                    </li>
                  </ul>
                  <!-- <ul
                    class="list-inline mb-0"
                    v-if="rowData.item.ctJobIdBase64 && rowData.item.cusId"
                  >
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="View"
                        :to="{
                          name: 'edit-sale-contactJob',
                          params: { ctJobId: rowData.item.ctJobIdBase64 },
                        }"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </router-link>
                    </li>
                  </ul> -->
                  <!-- <span v-if="rowData.item.ctJobIdBase64 && !rowData.item.ppCusId || !rowData.item.cusId">
                  -
                  </span> -->
                </template>
              </tableData>

              <br />

              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col-md-12">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
// import ChangeDate from "@/components/changeDateComponent";
import DatePicker from "vue2-datepicker";

// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.contactCustomer,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    // ChangeDate,
    DatePicker,
  },

  data() {
    return {
      loading: undefined,

      title: appConfig.branchContact,
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.branchContact,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      userBranchId: "",
      userBranch: "",
      filter: {
        contactInfo: "",
        subject: "",
        branchId: "",
        familyNameTh: "",
        teamId: "",
        userId: "",
        customer: "",
        contactType: "",
        startDate: "",
        endDate: "",
        type: "",
      },
      userOption: [],
      teamOption: [
        {
          type: "MANUAL",
        },
        {
          type: "CALL",
        },
        {
          type: "SMS",
        },
        {
          type: "EMAIL",
        },
      ],
      customerTypeOption: [
        {
          type: "N",
          nameTh: "ไม่ระบุ",
        },
        {
          type: "P",
          nameTh: "ลูกค้ามุ่งหวัง",
        },
        {
          type: "C",
          nameTh: "ลูกค้า",
        },
      ],
      branchIdSearch: "",
      localDataBranchId: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "customer",
          label: "ชื่อ",
          sortable: true,
        },
        {
          key: "contactInfo",
          label: "เบอร์โทรศัพท์",
          sortable: true,
        },

        // {
        //   key: "subject",
        //   label: "เรื่อง",
        //   sortable: true,
        // },
        {
          key: "message",
          label: "ข้อความ",
          sortable: true,
        },
        {
          key: "contactType",
          label: "ช่องทางติดต่อ",
          sortable: true,
        },

        {
          key: "createdAt",
          label: "วันที่เวลา",
        },
        {
          key: "callDirection",
          label: "สายเข้า-ออก",
        },
        {
          key: "duration",
          label: "เวลาโทร",
        },
        {
          key: "userName",
          label: "ผู้ติดต่อ",
          sortable: true,
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    // Set the initial number of items
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    // const arrayBranch = [];
    // const arrayBranchAll = [];
    // user.userAccessBranch.forEach((item) => {
    //   arrayBranch.push(item.branchId);
    //   arrayBranchAll.push(item);
    // });
    // this.userBranchId = user.branchId;
    // this.userBranch = arrayBranch;
    this.rowParts = this.items.length;
    this.getData();
  },
  created() {
    this.getLocalData();

    // this.getTeam();
    // this.getUser();
  },
  methods: {
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName != null ? familyName : ""}`;
    },
    getSeconds(sec) {
      var date = new Date(0);
      date.setSeconds(sec);
      var timeString = date.toISOString().substr(11, 8);
      return timeString;
      // console.log(timeString);
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId[0];

      console.log("สาขาาาาาา", this.localDataBranchId);
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.branchIdSearch.branchId];
      }
      this.getData();
    },
    onFiltered(filteredItems) {
      this.rowData = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    getData: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/branch-contact", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.localDataBranchId != null
                ? this.localDataBranchId
                : this.filter.branchId.branchId,
            customer: this.filter.customer,
            contactInfo: this.filter.contactInfo,
            // subject: this.filter.subject,
            contactType:
              this.filter.contactType != null
                ? this.filter.contactType.type
                : undefined,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            type: this.filter.type != null ? this.filter.type.type : undefined,
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            // appConfig.swal.title.Unauthorization,
            appConfig.swal.type.error
            // appConfig.swal.title.Unauthorization
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
